<template>
    <div class="active_container">
        <!--  -->
        <div class="null_img1" v-if="tableData.code==1">
            <img src="../../assets/Slice46.png" />
            <div class="text">暂无活动，敬请期待</div>
        </div>
        <!-- 活动已结束 -->
        <div class="active_null_img" v-if="tableData.code==2">
            <img src="../../assets/img3.png" />
        </div>
        <!-- 活动中 -->
        <div class="active_null_img" v-if="tableData.code==3&&tableData.data.activity_status!=2">
            <img src="../../assets/img2.png" />
        </div>
        <div class="active_null_img" v-if="tableData.code==3&&tableData.data.activity_status==2">
            <img src="../../assets/img3.png" />
        </div>
         <!-- 暂无活动 -->
        <div class="null_img" v-if="tableData.code==4">
            <img src="../../assets/img1.png" />
        </div>
        <div v-if="tableData.code!=0" class="lookBtn" @click="lookBtn">查看报名记录</div>
    </div>
</template>
<script>
import {unionActivity_api} from "@/api/deal"
export default {
  data() {
    return {
      activeShow: 2,
      tableData:''
    };
  },
  created() {
    document.title = "活动报名";
    this.getApply()
  },
  methods: {
    getApply() {
      unionActivity_api().then(res=>{
        // if(res.code==0){
          this.tableData = res;
          if(res.code==0){
            this.$router.push("./apply")
          }
        // }
      })
    },
    lookBtn(){
      this.$router.push("./activityList")
    }
  }
};
</script>
<style>
.null_img {
  width: 100%;
  height: 100vh;
}
.null_img1{
  width: 396px;height:396px;margin: 277px auto 0
}
img {
  width: 100%;
  height: 100%;
}
.active_null_img {
  width: 100%;
  height: 100vh;
}

.text{
  color:rgba(183, 183, 183, 1);
  font-size: 30px;
  text-align: center;
}
.lookBtn {
  position: fixed;
  top: 700px;
  left: 50%;
  transform: translateX(-50%);
  width: 372px;
  height: 100px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 2px solid #ffffff;
  font-size: 36px;color:#ffffff;text-align: center;
  line-height:100px
}
.status_text{
  position: fixed;
  left: 50%;
  top: 300px;font-size: 40px;
  transform: translateX(-50%)
}

.status_text1{
  position: fixed;
  left: 50%;
  top: 500px;font-size: 60px;
  transform: translateX(-50%)
}
</style>